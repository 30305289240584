.header {
  display: flex;
  align-items: center;
  background-image: linear-gradient(180deg, #264F8F, #3C6198);
  text-align: center;
  height: 40px;
  width: 100vw;
  max-width: 600px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);

  .title {
    flex: 1;
  }

  span {
    color: #fff;
    font-stretch: normal;
    font-style: normal;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 900;
    text-shadow: -2px 0 1px #333,
      0 2px 1px #333,
      2px 0 1px #333,
      0 -2px 1px #333,
      -1px -1px 1px #333,
      -1px 1px 1px #333,
      1px -1px 1px #333,
      1px 1px 1px #333;
  }

  span.second {
    color: #f7d93b;
  }
}