.layout {
  &--avatar {
    background-color: green
    width 100px
    height 100px
  }

  &--speech:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #4A90E2;
    border-top: 10px solid #4A90E2;
    border-bottom: 10px solid transparent;
    right: 120px;
    bottom: -23px;
  }

  &--speech:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid transparent;
    right: 124px;
    bottom: -13px;
  }

  &--center {
    margin: 0 auto;
  }
}